var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sector"},[_c('div',{staticClass:"sector-head"},[_c('h1',{staticClass:"sector-head-title"},[_vm._v(_vm._s(_vm.titles.sector))])]),_c('div',{staticClass:"form-field-plus"},[_c('div',{staticClass:"field-plus-legend"},[_vm._v("Clique para vincular")]),_c('i',{staticClass:"fa fa-long-arrow-right arrow1",attrs:{"aria-hidden":"true"}}),_c('div',[_c('v-btn',{attrs:{"fab":"","dark":"","color":"secondary"},on:{"click":function($event){return _vm.goTo()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-account-plus ")])],1)],1)]),(!_vm.users.length)?_c('v-alert',{attrs:{"text":"","dense":"","color":"gray","icon":"mdi-alert-circle-outline","border":"left"}},[_vm._v(" Clique no botão abaixo para vincular alunos! ")]):_c('div',{staticClass:"box-table"},[_c('v-data-table',{staticClass:"data-table elevation-2",attrs:{"headers":_vm.headers,"items":_vm.users,"page":_vm.page,"sort-by":"name","items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){return [_c('v-avatar',{attrs:{"color":"#dddddd","size":"40"}})]}},{key:"item.managers",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","title":"Visualizar"},on:{"click":function($event){return _vm.viewManagers(item)}}},[_vm._v(" "+_vm._s(item.managers)+" ")])]}},{key:"item.allowed",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.getColorComputed(item.allowed),"dark":"","outlined":""}},[_vm._v(" "+_vm._s(item.allowed ? 'ativo' : 'inativo')+" ")])]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getIconGenderComputed(item.gender).color}},[_vm._v(" "+_vm._s(_vm.getIconGenderComputed(item.gender).icon)+" ")])]}},{key:"item.activated",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getIconActivatedComputed(item.activated).color}},[_vm._v(" "+_vm._s(_vm.getIconActivatedComputed(item.activated).icon)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.action && item.id === _vm.itemIdActivated)?_c('div',{staticClass:"confirm"},[_c('div',[_vm._v("Confirma ação?")]),_c('div',[_c('v-btn',{staticClass:"btn-action option confirm-ok",attrs:{"color":"secondary","elevation":"0","x-small":"","rounded":"","dark":""},on:{"click":function($event){return _vm.actionConfirmUnlink(item.id, true)}}},[_vm._v(" Sim ")]),_c('v-btn',{staticClass:"btn-action option",attrs:{"color":"red","elevation":"0","x-small":"","rounded":"","dark":""},on:{"click":function($event){return _vm.actionConfirmUnlink(item.id, false)}}},[_vm._v(" Não ")])],1)]):_c('v-btn',{staticClass:"btn-action vinculate",attrs:{"color":"red","small":"","rounded":"","plain":"","elevation":"1","dark":""},on:{"click":function($event){return _vm.actionUnlink(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-minus ")]),_vm._v(" Desvincular ")],1)]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }