export default {
    users: [
        {
            id: 1,
            avatar: 'foto.jpg',
            campaigns: '4 / 0',
            name: 'Tiago Santos',
            email: 'tiagosantos@gmail.com',
            age: 14,
            gender: 'm',
            activated: false,
            allowed: true
        },
        {
            id: 2,
            avatar: 'foto2.jpg',
            name: 'Cintia Tavares',
            campaigns: '3 / 1',
            email: 'cintiatav@gmail.com',
            age: 12,
            gender: 'f',
            activated: true,
            allowed: true
        }
    ]
};
